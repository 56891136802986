import React from 'react';
import './AboutPage.css';
// ADDITIONS TO ABOUT PAGE 
export default function Aboutpage() {
  return (
    <div className="homepage-container">
      <div className="about-header">
        <h3>Aaron Zamanzadeh - Software Engineer</h3>
      </div>
      
      <div className="about-me-bottom">
        <a
          className="about-me-links about-me-portfolio"
          href="https://zamanzadeh.com"
          target="_blank"
        >
          Portfolio
        </a>
        <a
          className="about-me-links about-me-github"
          href="https://github.com/Adzamanz"
          target="_blank"
        >
          GitHub
        </a>
        <a
          className="about-me-links about-me-resume"
          href="https://www.linkedin.com/in/aaron-zamanzadeh/"
          target="_blank"
        >
          Linked-in
        </a>
      </div>
    </div>
  );
}
